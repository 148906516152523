import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { faBars, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { Observable, of, ReplaySubject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, takeUntil } from "rxjs/operators";
import { IMenuItem, Menu2Service } from "./menu2.service";

@Component({
	selector: "cm-menu2",
	template: `
		<ng-container *ngIf="menu$ | async as menu">
			<nav class="position-relative" [ngClass]="{ mobile: mobile$ | async, show: mobileShowMenu }">
				<fa-icon
					[icon]="faBars"
					class="icon px-2 py-3"
					role="button"
					tabindex="0"
					(click)="toggleMenu(menu)"
				></fa-icon>
				<ng-container *ngTemplateOutlet="submenu; context: { sub: menu, root: true }"></ng-container>
			</nav>

			<ng-template #submenu let-sub="sub" let-root="root">
				<ul
					class="submenu m-0"
					[ngClass]="{
						'list-unstyled': style(root) === 'unstyled',
						'list-inline': style(root) === 'inline',
						shadow: !root && !(mobile$ | async)
					}"
				>
					<li
						*ngFor="let item of sub"
						class="menu-item position-relative text-nowrap"
						[ngClass]="{
							'list-inline-item': style(root) === 'inline',
							show: item.show || (mobile$ | async)
						}"
						(mouseover)="item.show = true"
						(mouseleave)="item.show = false"
					>
						<a
							*ngIf="item.url && !item.children.length && !checkIfExternal(item.url)"
							routerLink="{{ item.url }}"
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }"
							fragment="{{ item.fragment }}"
							class="d-block px-2 py-3"
							itemprop="url"
						>
							<span itemprop="name" class="text-uppercase font-weight-bold">{{ item.text }}</span>
						</a>
						<a
							*ngIf="item.url && !item.children.length && checkIfExternal(item.url)"
							href="{{ item.url }}"
							target="_blank"
							class="d-block px-2 py-3"
							itemprop="url"
						>
							<span itemprop="name" class="text-uppercase font-weight-bold">{{ item.text }}</span>
						</a>
						<ng-container *ngIf="item.children.length">
							<a
								*ngIf="item.url && !checkIfExternal(item.url)"
								routerLink="{{ item.url }}"
								routerLinkActive="active-link"
								fragment="{{ item.fragment }}"
								[routerLinkActiveOptions]="{ exact: true }"
								class="d-block px-2 py-3"
								itemprop="url"
							>
								<span class="flex-grow-1" itemprop="name" class="text-uppercase font-weight-bold">{{
									item.text
								}}</span>
								<fa-icon
									[icon]="faChevronDown"
									*ngIf="item.children.length"
									[ngClass]="{ 'd-none': mobile$ | async, 'ml-2': !(mobile$ | async) }"
								></fa-icon>
							</a>
							<a
								*ngIf="item.url && checkIfExternal(item.url)"
								href="{{ item.url }}"
								target="_blank"
								class="d-block px-2 py-3"
								itemprop="url"
							>
								<span class="flex-grow-1" itemprop="name" class="text-uppercase font-weight-bold">{{
									item.text
								}}</span>
								<fa-icon
									[icon]="faChevronDown"
									*ngIf="item.children.length"
									[ngClass]="{ 'd-none': mobile$ | async, 'ml-2': !(mobile$ | async) }"
								></fa-icon>
							</a>
							<div class="d-flex no-link p-3" *ngIf="!item.url" role="button">
								<span class="flex-grow-1" class="text-uppercase font-weight-bold">{{ item.text }}</span>
								<fa-icon
									[icon]="faChevronDown"
									*ngIf="item.children.length"
									[ngClass]="{ 'd-none': mobile$ | async, 'ml-2': !(mobile$ | async) }"
								></fa-icon>
							</div>
							<ng-container *ngIf="item.children.length">
								<ng-container
									*ngTemplateOutlet="submenu; context: { sub: item.children, root: false }"
								></ng-container>
							</ng-container>
						</ng-container>
					</li>
				</ul>
			</ng-template>
		</ng-container>
	`,
	styleUrls: ["./menu2.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Menu2Component implements OnChanges {
	@Input() appMenuid!: number;
	/**
	 * Style of root menu level
	 */
	@Input() rootStyle: "default" | "unstyled" | "inline" = "default";
	/**
	 * Style of submenu
	 */
	@Input() subStyle: "default" | "unstyled" | "inline" = "default";

	faBars = faBars;
	faChevronDown = faCaretDown;

	mobileShowMenu: boolean = false;
	itemFocusoutSub: Subscription | null = null;
	menuFocusoutSub: Subscription | null = null;
	ngOnDestroyRS = new ReplaySubject<void>();
	stayURL: string = "/";

	menu$: Observable<IMenuItemExt[] | null> = of(null);
	mobile$ = this.viewportService.windowSize$.pipe(map((w) => w < 1102));

	constructor(
		private menuService: Menu2Service,
		private viewportService: ViewportService,
		@Inject(DOCUMENT) private document: Document,
		router: Router,
	) {
		this.mobile$.pipe(takeUntil(this.ngOnDestroyRS)).subscribe((mobile) => {
			if (mobile) {
				this.document.body.classList.add("mobile");
			} else {
				this.document.body.classList.remove("mobile");
			}
		});

		router.events
			.pipe(
				takeUntil(this.ngOnDestroyRS),
				filter((event) => event instanceof NavigationStart),
				switchMap(() => this.menu$),
			)
			.subscribe((menu) => {
				if (menu) {
					this.hideMenu(menu);
					this.document.body.classList.remove("nav-open");
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.appMenuid) {
			this.menu$ = this.menuService.getMenu(changes.appMenuid.currentValue).pipe(
				map((items) => items && items.map((item) => ({ ...item, show: false }))),
				shareReplay(),
			);
		}
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}

	style(root: boolean) {
		return root ? this.rootStyle : this.subStyle;
	}

	hideMenu(menu: IMenuItemExt[]) {
		this.mobileShowMenu = false;
		this.recursiveHide(menu);
		this.document.body.classList.add("nav-open");
	}

	toggleMenu(menu: IMenuItemExt[]) {
		this.mobileShowMenu = !this.mobileShowMenu;

		if (!this.mobileShowMenu) {
			this.recursiveHide(menu);
			this.document.body.classList.remove("nav-open");
		} else {
			this.document.body.classList.add("nav-open");
		}
	}

	private recursiveHide(items: IMenuItemExt[]) {
		for (const item of items) {
			item.show = false;
			this.recursiveHide(item.children as IMenuItemExt[]);
		}
	}

	checkIfExternal(url: string) {
		return url.indexOf("http") === 0;
	}
}

interface IMenuItemExt extends IMenuItem {
	show: boolean;
}
