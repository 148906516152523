import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StarListingComponent } from "@core/app/star-listing/star-listing.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbProgressbarModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalFileUploadComponent } from "../admin/components/modal-file-upload/modal-file-upload.component";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { ImageModule } from "../image/image.module";
import { ReplaceLineBreaksPipe } from "../shared/pipes/nl2br.pipe";
import { BgComponent } from "./components/bg.component";
import { LayersComponent } from "./components/layers/layers.component";
import { SkewComponent } from "./components/skew.component";
import { TintComponent } from "./components/tint.component";

@NgModule({
	declarations: [
		BgComponent,
		TintComponent,
		DropzoneComponent,
		ModalFileUploadComponent,
		LayersComponent,
		ReplaceLineBreaksPipe,
		SkewComponent,
		StarListingComponent,
	],
	imports: [CommonModule, FormsModule, NgbProgressbarModule, NgbTooltipModule, FontAwesomeModule, ImageModule],
	exports: [
		BgComponent,
		TintComponent,
		DropzoneComponent,
		ModalFileUploadComponent,
		ReplaceLineBreaksPipe,
		LayersComponent,
		SkewComponent,
		StarListingComponent,
	],
})
export class LayoutModule {}
