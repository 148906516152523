import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SMap } from "@common/map2";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { upperFirst } from "@common/util";
import { map } from "rxjs/operators";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-hours2",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<span *ngFor="let hourLine of hours$ | async">
			{{ hourLine.start
			}}<ng-container *ngIf="hourLine.start !== hourLine.end"> - {{ hourLine.end }}</ng-container
			>: {{ hourLine.hours }}
		</span>
	`,
})
export class Hours2Component {
	hours$ = this.transfer.transfer$("GetOrgDeptHours", () =>
		this.http.post("/api/statement/GetOrgDeptHours", {}).pipe(
			map((res: any) => {
				const hours: SMap<string, { hours: string; start: string; end: string }[]> = new SMap();
				let cur: { hours: string; start: string; end: string } | null = null;
				for (const row of res.results) {
					let from = row.time_from;
					if (from && from.startsWith("0")) {
						from = from.substring(1);
					}
					if (from) {
						from = from.replace(" PM", "pm").replace(" AM", "am");
					}

					let to = row.time_to;
					if (to && to.startsWith("0")) {
						to = to.substring(1);
					}
					if (to === "11:59 PM") {
						to = "12:00 AM";
					}
					if (to) {
						to = to.replace(" PM", "pm").replace(" AM", "am");
					}

					const hourRange = from && to ? `${from} - ${to}` : "Closed";
					let day = "";
					if (row.day_of_week === "Saturday" || row.day_of_week === "Sunday") {
						day = row.day_of_week.toLowerCase().substring(0, 3).toUpperCase();
					} else {
						day = upperFirst(row.day_of_week.toLowerCase()).substring(0, 1);
					}

					if (cur && cur.hours === hourRange) {
						cur.end = day;
					} else if (hourRange !== "Closed") {
						cur = { hours: hourRange, start: day, end: day };
						hours.getOrInsert(row.org_dept, []).push(cur);
					}
				}
				return hours.get("Sales").unwrap();
			}),
		),
	);

	constructor(private http: HttpClient, private transfer: TransferRxService) {}
}
