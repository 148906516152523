import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { IPageData } from "@model/page-data";
import { iter } from "shared/common";
import { CookieService } from "./cookie.service";
import { UserService } from "./user.service";

declare const global: any;

@Injectable({ providedIn: "root" })
export class GTMService {
	dataLayer = ((typeof window !== "undefined" ? (window as any) : (global as any)).dataLayer =
		(typeof window !== "undefined" ? (window as any) : (global as any)).dataLayer || []);

	constructor(
		private cookieService: CookieService,
		@Inject(DOCUMENT) private document: Document,
		@Inject("PAGE_DATA") private pageData: IPageData,
		private userService: UserService,
	) {}

	track(category: any, action: any, label: any, props?: any) {
		this.dataLayer.push({
			event: "ngTrackEvent",
			attributes: Object.assign(this.globalAttrs(category, action, label), props),
		});
	}

	trackEvent(event: any, props: any) {
		this.dataLayer.push({ event: event, attributes: Object.assign(this.globalAttrs(null, null, null), props) });
	}

	trackProductImpression(items: IProductItem[]) {
		this.dataLayer.push({ event: "productImpression", ecommerce: { currencyCode: "USD", impressions: items } });
	}

	trackProductClick(item: IProductItem) {
		this.dataLayer.push({
			event: "productClick",
			attributes: { label: item.name },
			label: item.name,
			ecommerce: { click: { products: [item] } },
		});
	}

	trackProductDetail(item: IProductItem) {
		this.dataLayer.push({ event: "productDetail", label: item.name, ecommerce: { detail: { products: [item] } } });
	}

	trackAddToCart(item: IProductItem) {
		this.dataLayer.push({
			event: "addToCart",
			label: item.name,
			attributes: { label: item.name, value: Number(item.price) * Number(item.quantity) },
			ecommerce: { currencyCode: "USD", add: { products: [item] } },
		});
	}

	trackRemoveFromCart(item: IProductItem) {
		this.dataLayer.push({
			event: "removeFromCart",
			attributes: { label: item.name, value: Number(item.price) * Number(item.quantity) },
			label: item.name,
			ecommerce: { remove: { products: [item] } },
		});
	}

	trackCheckoutStep(step: number, items: IProductItem[], wholesale: boolean) {
		this.dataLayer.push({
			event: "checkout",
			label: wholesale ? "wholesale" : "consumer",
			value: iter(items)
				.map((x) => Number(x.price) * Number(x.quantity))
				.sum(),
			ecommerce: { checkout: { actionField: { step }, products: items } },
			attributes: {
				label: "Cart",
				value: iter(items)
					.map((x) => Number(x.price) * Number(x.quantity))
					.sum(),
			},
		});
	}

	trackPurchase(orderInfo: IOrderInfo, items: IProductItem[]) {
		this.dataLayer.push({
			event: "purchase",
			attributes: {
				label: "Purchase",
				value: orderInfo.total,
			},
			ecommerce: {
				currencyCode: "USD",
				currency: "USD",
				purchase: {
					actionField: {
						id: orderInfo.id,
						affiliation: this.pageData.appInfo.data.siteName,
						revenue: orderInfo.total,
						tax: orderInfo.tax,
						shipping: orderInfo.shipping,
						coupon: orderInfo.coupon,
					},
					products: items,
				},
			},
		});
	}

	private globalAttrs(category: any, action: any, label: any) {
		const route = (typeof window !== "undefined" ? (window as any) : (global as any)).route;
		const statementResults = route && route.statement ? route.statement.results : null;

		return {
			category: category,
			action: action,
			label: label,
			pageType: route ? route.title : null,
			pageTitle: statementResults && statementResults[0] ? statementResults[0].title : this.document.title,
			authRole: this.userService.get("auth_role"),
			customerId: this.userService.get("customerId"),
			isEmployee: this.userService.loggedIn() && !this.userService.isCustomer(),
			isCustomer: this.userService.isCustomer(),
			isVisitor: !this.userService.loggedIn(),
			salesPersonId: this.userService.get("salespersonId"),
			salesPersonName: this.userService.get("user_display_name"),
			uid: this.cookieService.get("uid"),
			userId: this.userService.get("userid"),
			userLoggedIn: this.userService.loggedIn(),
			visitorId: this.cookieService.get("visitorId"),
			visitorVisitId: this.cookieService.get("visitorVisitId"),
		};
	}
}

interface IOrderInfo {
	id: string | number;
	total: string | number;
	tax: string | number;
	shipping: string | number;
	coupon: null | string | number;
}

interface IProductItem {
	id: number;
	name: string;
	price: string | number;
	brand: string;
	category: string;
	variant?: string;
	position?: number;
	list?: string;
	quantity?: number;
}
