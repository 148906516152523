import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LayoutModule } from "../layout/layout.module";
import { UtilModule } from "../util/util.module";
import { EditAddEmployeeComponent } from "./components/add-employee.component";
import { EmployeeAutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { EmployeePortalComponent } from "./components/employee-portal.component";

@NgModule({
	imports: [CommonModule, LayoutModule, NgbModule, FormsModule, FontAwesomeModule, UtilModule],
	exports: [EditAddEmployeeComponent, EmployeePortalComponent],
	declarations: [EditAddEmployeeComponent, EmployeeAutocompleteComponent, EmployeePortalComponent],
})
export class EmployeePortalModule {}
