import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSegment, UrlMatchResult, UrlMatcher } from '@angular/router';
import { CoreModule } from '../core.module';

import { RouteResolver } from "@core/app/route-resolver.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@core/environments/environment';
import { RootComponent } from "@core/app/pages/root/root.component";
import { PAGE_DATA } from "./page-data";
import { ToastNoAnimationModule } from 'ngx-toastr';
    import { PrerenderModule } from "shared";

export const routes: Routes = [
    {
        matcher: routeMatcher0 as UrlMatcher,
        loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Admin",
            pageTemplateId: 88,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/new-admin).*$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher1 as UrlMatcher,
        loadChildren: () => import("./pages/page-job-posting/page-job-posting.module").then((m) => m.PageJobPostingModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "JobPosting",
            pageTemplateId: 87,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/jobs\\/application)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher2 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Account",
            pageTemplateId: 60,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/my-account)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher3 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Home",
            pageTemplateId: 39,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/)([0-9]+)?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher4 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Contact",
            pageTemplateId: 138,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/contact)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher5 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "About",
            pageTemplateId: 224,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/about)$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher6 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 253,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/brands)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher7 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 255,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/product\\/(?:[^\\/]+))(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher8 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 256,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/my-cart)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher9 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 257,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher10 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 258,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/post-order)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher11 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 298,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher12 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 302,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/contractors)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher13 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 303,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/departments)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher14 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 304,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/employee-portal)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher15 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 306,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/windows-doors)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher16 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 307,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/kitchen-bath)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher17 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 308,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/building-supplies)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher18 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 309,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/lumber)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher19 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 310,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/roofing)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher20 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 311,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/decking)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher21 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 312,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/insulation)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher22 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 313,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/siding-trim)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher23 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 314,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/flooring-tile)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher24 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 315,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/paint-stain)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher25 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 317,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/hardware-tools)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher26 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 318,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/products\\/seasonal)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher27 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 319,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/marina)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher28 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 367,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/andersen)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher29 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 370,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/shop)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher30 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 292,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/category\\/(.+?))(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&offer_cat=2&page=3",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher31 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 488,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services\\/kitchen-bath-design)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher32 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 487,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services\\/insulation)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher33 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 486,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services\\/estimating)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher34 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 485,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services\\/delivery)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher35 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 484,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services\\/customer)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher36 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 483,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/services\\/contractor-sales)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher37 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 475,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/facebook)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher38 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 490,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/featured-product)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher39 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 13,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/new-admin).*$/, segments, true); }
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/jobs\/application)(?:\?.*)?$/, segments, false); }
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false); }
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/)([0-9]+)?(?:\?.*)?$/, segments, false); }
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/contact)(?:\?.*)?$/, segments, false); }
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/about)$/, segments, false); }
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/brands)(?:\?.*)?$/, segments, false); }
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/product\/(?:[^\/]+))(?:\?.*)?$/, segments, false); }
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/my-cart)(?:\?.*)?$/, segments, false); }
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/post-order)(?:\?.*)?$/, segments, false); }
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services)(?:\?.*)?$/, segments, false); }
export function routeMatcher12(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/contractors)(?:\?.*)?$/, segments, false); }
export function routeMatcher13(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/departments)(?:\?.*)?$/, segments, false); }
export function routeMatcher14(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/employee-portal)(?:\?.*)?$/, segments, false); }
export function routeMatcher15(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/windows-doors)(?:\?.*)?$/, segments, false); }
export function routeMatcher16(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/kitchen-bath)(?:\?.*)?$/, segments, false); }
export function routeMatcher17(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/building-supplies)(?:\?.*)?$/, segments, false); }
export function routeMatcher18(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/lumber)(?:\?.*)?$/, segments, false); }
export function routeMatcher19(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/roofing)(?:\?.*)?$/, segments, false); }
export function routeMatcher20(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/decking)(?:\?.*)?$/, segments, false); }
export function routeMatcher21(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/insulation)(?:\?.*)?$/, segments, false); }
export function routeMatcher22(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/siding-trim)(?:\?.*)?$/, segments, false); }
export function routeMatcher23(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/flooring-tile)(?:\?.*)?$/, segments, false); }
export function routeMatcher24(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/paint-stain)(?:\?.*)?$/, segments, false); }
export function routeMatcher25(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/hardware-tools)(?:\?.*)?$/, segments, false); }
export function routeMatcher26(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/products\/seasonal)(?:\?.*)?$/, segments, false); }
export function routeMatcher27(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/marina)(?:\?.*)?$/, segments, false); }
export function routeMatcher28(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/andersen)(?:\?.*)?$/, segments, false); }
export function routeMatcher29(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/shop)(?:\?.*)?$/, segments, false); }
export function routeMatcher30(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/category\/(.+?))(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher31(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services\/kitchen-bath-design)(?:\?.*)?$/, segments, false); }
export function routeMatcher32(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services\/insulation)(?:\?.*)?$/, segments, false); }
export function routeMatcher33(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services\/estimating)(?:\?.*)?$/, segments, false); }
export function routeMatcher34(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services\/delivery)(?:\?.*)?$/, segments, false); }
export function routeMatcher35(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services\/customer)(?:\?.*)?$/, segments, false); }
export function routeMatcher36(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/services\/contractor-sales)(?:\?.*)?$/, segments, false); }
export function routeMatcher37(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/facebook)(?:\?.*)?$/, segments, false); }
export function routeMatcher38(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/featured-product)(?:\?.*)?$/, segments, false); }
export function routeMatcher39(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false); }

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
    if (regex.test("/" + segments.map(x => x.path).join("/"))) {
        if (hasChildRouter) {
            return { consumed: [segments[0]] };
        } else {
            return { consumed: segments };
        }
    } else {
        return null;
    }
}

@NgModule({
    declarations: [RootComponent],
    imports: [
        CommonModule,
        CoreModule.forRoot(),
        ToastNoAnimationModule.forRoot({ positionClass: 'toast-top-center' }),
        PrerenderModule,
        RouterModule.forRoot(routes, {
            initialNavigation: "enabledBlocking",
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            onSameUrlNavigation: "reload",
        }),
        ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
        
    ],
    providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }, ],
    entryComponents: [RootComponent],
    bootstrap: [RootComponent],
})
export class AppModule { }
