import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { NumFormatPipe } from "@common/pipes/numFormat";
import { ImageModule } from "@core/app/image/image.module";
import { CheckboxSelectorComponent } from "@core/app/shared/components/search-filter/components/checkbox-selector/checkbox-selector.component";
import { KeywordSelectorComponent } from "@core/app/shared/components/search-filter/components/keyword-selector/keyword-selector.component";
import { RangeSelectorComponent } from "@core/app/shared/components/search-filter/components/range-selector/range-selector.component";
import { SearchFilterComponent } from "@core/app/shared/components/search-filter/search-filter.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
	NgbAccordionModule,
	NgbCollapseModule,
	NgbProgressbarModule,
	NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { SearchFilterRowComponent } from "../shared/components/search-filter-row/search-filter-row.component";
import { PredictiveSelectorComponent } from "../shared/components/search-filter/components/predictive-selector/predictive-selector.component";
import { RangeInputSelectorComponent } from "../shared/components/search-filter/components/range-input-selector/range-input-selector.component";
import { SelectSelectorComponent } from "../shared/components/search-filter/components/select-selector/select-selector.component";
import { SearchModule } from "../search/search.module";
import { SearchFilterGroupComponent } from "../shared/components/search-filter-group/search-filter-group.component";

@NgModule({
	declarations: [
		SearchFilterComponent,
		SearchFilterRowComponent,
		SearchFilterGroupComponent,
		RangeSelectorComponent,
		RangeInputSelectorComponent,
		CheckboxSelectorComponent,
		KeywordSelectorComponent,
		PredictiveSelectorComponent,
		SelectSelectorComponent,
		NumFormatPipe,
	],
	imports: [
		CommonModule,
		NgbAccordionModule,
		NgbCollapseModule,
		NgbProgressbarModule,
		NgbTooltipModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		ImageModule,
		FontAwesomeModule,
		SearchModule,
	],
	exports: [
		SearchFilterComponent,
		SearchFilterRowComponent,
		SearchFilterGroupComponent,
		RangeSelectorComponent,
		RangeInputSelectorComponent,
		CheckboxSelectorComponent,
		KeywordSelectorComponent,
		PredictiveSelectorComponent,
		SelectSelectorComponent,
		NumFormatPipe,
	],
})
export class InventoryModule {}
