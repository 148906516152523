import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule, UtilModule as UtilModuleShared } from "shared";
import { AccountModule } from "../account/account.module";
import { FormModule } from "../form/form.module";
import { ImageModule } from "../image/image.module";
import { InventoryModule } from "../inventory/inventory.module";
import { LayoutModule } from "../layout/layout.module";
import { MaggiesModule } from "../maggies/maggies.module";
import { Map2Module } from "../map2/map2.module";
import { Pagination2Module } from "../pagination2/pagination2.module";
import { SliderModule } from "../slider/slider.module";
import { UtilModule } from "../util/util.module";
import { HempBrandsSectionComponent } from "./brands/brands-section.component";
import { HempCartComponent } from "./cart/cart.component";
import { HempContactMapRowComponent } from "./contact/map-row.component";
import { HempHomeBrandsRowComponent } from "./home/brands-row.component";
import { HempHomeReviewsComponent } from "./home/reviews.component";
import { HempLabSectionComponent } from "./lab-results/lab-section.component";
import { HempLabTestsComponent } from "./lab-tests.component";
import { HempMfrTrackingNumberComponent } from "./mfr-tracking-number/mfr-tracking-number.component";
import { HempAccountColComponent } from "./my-account/account-col.component";
import { HempAccountComponent } from "./my-account/account.component";
import { HempOfferCardComponent } from "./offer-card.component";
import { HempOfferSlidesComponent } from "./offer-slides.component";
import { OfferAddReviewComponent } from "./offer/add-review.component";
import { HempOfferImgsComponent } from "./offer/offer-imgs.component";
import { HempContactOffer } from "./offer/offer.component";
import { HempOfferReviewsComponent } from "./offer/reviews.component";
import { HempPostOrderComponent } from "./post-order/post-order.component";
import { HempListingGridComponent } from "./products/listing-grid.component";
import { HempProductsCuratedComponent } from "./products/products-curated.component";
import { HempProductsLinksComponent } from "./products/products-links.component";
import { HempProductsComponent } from "./products/products.component";
import { HempSearchFilterComponent } from "./search-filter/search-filter.component";
import { HempCbdGuideTabsComponent } from "./what-is-cbd/cbd-guide-tabs.component";
import { HempCbdGuideCbdComponent } from "./what-is-cbd/cbd-guide/cbd.component";
import { HempCbdGuideCbgComponent } from "./what-is-cbd/cbd-guide/cbg.component";
import { HempCbdGuideCbnComponent } from "./what-is-cbd/cbd-guide/cbn.component";
import { HempCbdGuideIntroComponent } from "./what-is-cbd/cbd-guide/intro.component";
import { HempWholesaleAppComponent } from "./wholesale/wholesale-app.component";
import { HempWishlistComponent } from "./wishlist.component";

@NgModule({
	imports: [
		AccountModule,
		CommonModule,
		FontAwesomeModule,
		LayoutModule,
		Map2Module,
		SliderModule,
		UtilModule,
		FormModule,
		FormsModule,
		ImageModule,
		ModalModule,
		RouterModule,
		InventoryModule,
		Pagination2Module,
		NgbProgressbarModule,
		UtilModuleShared,
		MaggiesModule,
	],
	exports: [
		HempCbdGuideTabsComponent,
		HempContactMapRowComponent,
		HempHomeBrandsRowComponent,
		HempBrandsSectionComponent,
		HempAccountComponent,
		HempProductsComponent,
		HempOfferSlidesComponent,
		HempHomeReviewsComponent,
		HempContactOffer,
		HempSearchFilterComponent,
		HempCartComponent,
		HempPostOrderComponent,
		HempProductsCuratedComponent,
		HempLabSectionComponent,
		HempWholesaleAppComponent,
		HempMfrTrackingNumberComponent,
	],
	declarations: [
		HempCbdGuideTabsComponent,
		HempCbdGuideIntroComponent,
		HempContactMapRowComponent,
		HempHomeBrandsRowComponent,
		HempBrandsSectionComponent,
		HempAccountColComponent,
		HempOfferCardComponent,
		HempOfferSlidesComponent,
		HempHomeReviewsComponent,
		HempContactOffer,
		HempOfferImgsComponent,
		HempSearchFilterComponent,
		HempCartComponent,
		HempPostOrderComponent,
		HempAccountComponent,
		HempProductsComponent,
		HempListingGridComponent,
		HempProductsCuratedComponent,
		HempProductsLinksComponent,
		HempWishlistComponent,
		OfferAddReviewComponent,
		HempOfferReviewsComponent,
		HempLabTestsComponent,
		HempLabSectionComponent,
		HempCbdGuideCbdComponent,
		HempCbdGuideCbgComponent,
		HempCbdGuideCbnComponent,
		HempWholesaleAppComponent,
		HempMfrTrackingNumberComponent,
	],
})
export class HempOilModule {}
