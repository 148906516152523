import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CampaignPickerComponent } from "@core/app/adwords/components/campaign-picker/campaign-picker.component";
import { ButtonModule } from "@core/app/button/button.module";
import { GroupByPipe } from "@core/app/group-by.pipe";
import { ImageModule } from "@core/app/image/image.module";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { Map2Module } from "@core/app/map2/map2.module";
import { Pagination2Module } from "@core/app/pagination2/pagination2.module";
import { SearchModule } from "@core/app/search/search.module";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { TableFiltersComponent } from "@core/app/shared/components/table-filters/table-filters.component";
import { TableSelectsComponent } from "@core/app/shared/components/table-selects/table-selects.component";
import { TableComponent } from "@core/app/shared/components/table/table.component";
import { SliderModule } from "@core/app/slider/slider.module";
import { SlugifyPipe } from "@core/app/slugify.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
	NgbAccordionModule,
	NgbCollapseModule,
	NgbDatepickerModule,
	NgbDropdownModule,
	NgbModalModule,
	NgbNavModule,
	NgbProgressbarModule,
	NgbTabsetModule,
	NgbTimepickerModule,
	NgbTooltipModule,
	NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "shared";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { EditWidgetComponent } from "../edit-widget/edit-widget.component";
import { EmployeePortalModule } from "../employee-portal/employee-portal.module";
import { FormModule } from "../form/form.module";
import { HempOilModule } from "../hemp-oil/hemp-oil.module";
import { LazyModule } from "../lazy/lazy.module";
import { MaggiesModule } from "../maggies/maggies.module";
import { Menu2Component } from "../menu2/menu2.component";
import { PromotionBannerComponent } from "../promotion/components/promotion-banner/promotion-banner.component";
import { PromotionEmbedsComponent } from "../promotion/components/promotion-banner/promotion-embeds.component";
import { UtilModule } from "../util/util.module";

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		NgbAccordionModule,
		NgbModalModule,
		NgbProgressbarModule,
		NgbTypeaheadModule,
		NgbDropdownModule,
		NgbTabsetModule,
		NgbCollapseModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbNavModule,
		NgbTimepickerModule,
		RouterModule,
		RouterModule,
		ImageModule,
		ButtonModule,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		MaggiesModule,
		Pagination2Module,
		ButtonModule,
		UtilModule,
		FormModule,
		ModalModule,
		HempOilModule,
		LazyModule,
		EmployeePortalModule,
	],
	declarations: [
		GroupByPipe,
		SlugifyPipe,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		SocialLinks2Component,
		CampaignPickerComponent,
		PromotionBannerComponent,
		PromotionEmbedsComponent,
		EditWidgetComponent,
		Menu2Component,
	],
	exports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		GroupByPipe,
		NgbAccordionModule,
		NgbModalModule,
		NgbProgressbarModule,
		NgbTypeaheadModule,
		NgbDropdownModule,
		NgbTabsetModule,
		NgbCollapseModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbNavModule,
		NgbTimepickerModule,
		RouterModule,
		SlugifyPipe,
		DropzoneComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ImageModule,
		SocialLinks2Component,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		MaggiesModule,
		CampaignPickerComponent,
		Pagination2Module,
		ButtonModule,
		FormModule,
		ModalModule,
		HempOilModule,
		PromotionBannerComponent,
		PromotionEmbedsComponent,
		LazyModule,
		EditWidgetComponent,
		UtilModule,
		Menu2Component,
		EmployeePortalModule,
	],
	providers: [Location],
})
export class SharedModule {}
