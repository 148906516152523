import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ImageModule } from "../image/image.module";
import { LayoutModule } from "../layout/layout.module";
import { MaggiesCatOffersComponent } from "./components/cat-offers.component";
import { MaggiesHomeCatsComponent } from "./components/home-cats.component";
import { MaggiesSideCatsComponent } from "./components/side-cats.component";

@NgModule({
	declarations: [MaggiesHomeCatsComponent, MaggiesSideCatsComponent, MaggiesCatOffersComponent],
	imports: [CommonModule, ImageModule, LayoutModule, RouterModule],
	exports: [MaggiesHomeCatsComponent, MaggiesSideCatsComponent, MaggiesCatOffersComponent],
})
export class MaggiesModule {}
