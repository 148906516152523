import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { ApolloClientOptions, InMemoryCache, split } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SharedModule } from "@core/app/shared/shared.module";
import { config } from "@fortawesome/fontawesome-svg-core";
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, NamedOptions } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { createUploadLink } from "apollo-upload-client";
import { Ng2UiAuthModule, StorageType } from "ng2-ui-auth";
import { ModalModule } from "shared";
import { AccountModule } from "./account/account.module";
import { EqualValidatorDirective } from "./equal-validator.directive";
import { FooterComponent } from "./footer/components/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HoursComponent } from "./header/hours.component";
import { Hours2Component } from "./header/hours2.component2";
import { ImageModule } from "./image/image.module";
import { InventoryModule } from "./inventory/inventory.module";
import { RouteResolver } from "./route-resolver.service";
import { SliderModule } from "./slider/slider.module";

const auth = {
	storageType: StorageType.LOCAL_STORAGE,
};

@NgModule({
	declarations: [EqualValidatorDirective, HeaderComponent, HoursComponent, Hours2Component, FooterComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		Ng2UiAuthModule.forRoot(auth),
		RouterModule.forChild([]),
		SharedModule,
		SliderModule,
		InventoryModule,
		ImageModule,
		AccountModule,
		ModalModule,
	],
	exports: [
		EqualValidatorDirective,
		SharedModule,
		HeaderComponent,
		HoursComponent,
		Hours2Component,
		SliderModule,
		InventoryModule,
		ImageModule,
		AccountModule,
		FooterComponent,
	],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: (httpLink: HttpLink): ApolloClientOptions<any> => ({
				cache: new InMemoryCache(),
				link: httpLink.create({ uri: "/graphql" }),
				defaultOptions: { query: { fetchPolicy: "no-cache" }, watchQuery: { fetchPolicy: "no-cache" } },
			}),
			deps: [HttpLink],
		},
		{
			provide: APOLLO_NAMED_OPTIONS,
			useFactory: (httpLink: HttpLink): NamedOptions => {
				// const http = httpLink.create({ uri: "/graphql-node" });
				const http = createUploadLink({ uri: "/graphql-node" });

				const ws = new WebSocketLink({
					uri: `wss://${location.host}/graphql-node`,
					options: { reconnect: true, lazy: true },
				});

				const link = split(
					// split based on operation type
					({ query }) => {
						const def = getMainDefinition(query);
						return def.kind === "OperationDefinition" && def.operation === "subscription";
					},
					ws,
					http,
				);

				return {
					node: {
						cache: new InMemoryCache(),
						link,
						defaultOptions: { query: { fetchPolicy: "no-cache" }, watchQuery: { fetchPolicy: "no-cache" } },
					},
				};
			},
			deps: [HttpLink],
		},
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
